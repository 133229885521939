(function ($) {
    var menuTimer, $mobilemenuWrapper, $multiselectMobile, $mobileOverlay, $mobilemenu, $navbarToggler, $navbarTogglerHtml, $mobileSearchForm, $mainMenuLevel
    var touchMoveScrollSwitch = true;
    var focusOnOpen = false;
    $(function () {
        jQuery.extend(verge);
        //Set Cached selectors
        $mobilemenuWrapper = $("#menu");
        $multiselectMobile = $("#menu", "#menu_multilevelpushmenu");

        $navbarToggler = $("#mobileNavbarToggler");
        $navbarSearchToggler = $("#mobileNavbarSearchToggler");
        $mobileSearchInput = $("#mobileSearchInput");
        $mobileSearchForm = $('.mobile-search-form');

        $('.navbar-search-toggler').click(function () {
            var $this = $(this);
            $mobileSearchForm.slideToggle();
            $this.find('i').toggleClass('fa-search').toggleClass('fa-times');
        });

        $('#main-content').prepend("<div class=\"overlay\"></div>");
        $mobileOverlay = $(".overlay");

        //Instantiate mobile menu object into cover mode with HTML implementation
        $mobilemenuWrapper.multilevelpushmenu({
            mode: 'cover',
            fullCollapse: true,
            backItemIcon: 'fa fa-caret-left',
            backText: 'Go Back',
            groupIcon: 'fa fa-caret-right',
            menuWidth: '100%',
            menuHeight: '100%',
            collapsed: true,
            onMenuReady: function () {
                $mobilemenu = $("#menu_multilevelpushmenu");
                $mobilemenuWrapper.removeClass('domReady');
            },
            onCollapseMenuStart: function () {
                if ($mobilemenuWrapper.multilevelpushmenu('activemenu').attr('data-level') === "0") {
                    $mobileOverlay.addClass('out');
                }
            },
            onCollapseMenuEnd: function () {
                if (!$mobilemenuWrapper.multilevelpushmenu('activemenu').length) {
                    $('html').removeClass('freeze');
                    $('body').removeClass('freeze');
                    setTimeout(function () {
                        $mobileOverlay.removeClass('show').removeClass('out');
                    }, 300);
                }
                ScrollMobileMenu($mobilemenuWrapper.multilevelpushmenu('activemenu'));
            },
            onExpandMenuStart: function () {
                //Fix a bug where the menu doesn't open up to be full-width
                $('html').addClass('freeze');
                $('body').addClass('freeze');
                $mobilemenuWrapper.multilevelpushmenu('redraw');
                setTimeout(function () {
                    $mobileOverlay.addClass('show');
                }, 300);
            },
            onExpandMenuEnd: function () {
                ScrollMobileMenu($mobilemenuWrapper.multilevelpushmenu('activemenu'));
                if (focusOnOpen !== false) {
                    $(focusOnOpen).focus();
                    focusOnOpen = false;
                }
            },
            onMenuSwipe: function () {
                //Prevent menu swipe event from stealing focus from form elements
                return false;
            },
            onItemClick: function () {
                //Manually send the user to a link provided it has a valid href
                var event = arguments[0];
                console.log('changed touches ' + $(arguments[0].originalEvent.changedTouches));
                if (touchMoveScrollSwitch) {
                    var $anchor = $(event.target);
                    var url = $anchor.attr('href');
                    var userTarget = $anchor.attr('target');
                    var smartTarget = url.startsWith("/") || url.startsWith("tel:") || url.startsWith("mailto:") ? "_self" : "_blank";
                    var target = userTarget || smartTarget;

                    if (url.length) {
                        window.open(url, target);
                    }
                }
            }
        });

        mainMenuLevel = $mobilemenu.children();
        calculateMenuHeight();

        var touchTimer;
        $('.levelHolderClass').on('scroll', function (e) {
            touchMoveScrollSwitch = false;
            clearTimeout(touchTimer);
            touchTimer = setTimeout(function () {
                touchMoveScrollSwitch = true;
            }, 200);
        });

        //Redraw the mobile menu to enable responsiveness
        $(window).on('resize orientationchange', function () {
            //Only run resize function every 50ms while in the correct viewport to lower overhead of client function
            if (isViewportInMobile(992)) {
                if (typeof ($mobilemenuWrapper.multilevelpushmenu('activemenu')[0]) !== 'undefined' && ($mobilemenuWrapper.multilevelpushmenu('activemenu'))[0].scrollHeight > $mobilemenuWrapper.multilevelpushmenu('activemenu').height()) {
                    toggleMenuClick();
                }
                clearTimeout(menuTimer);
                menuTimer = setTimeout(function () {
                    $mobilemenuWrapper.multilevelpushmenu('redraw');
                    ScrollMobileMenu($mobilemenuWrapper.multilevelpushmenu('activemenu'));
                    //console.log('redraw hit - ' + menuTimer);
                }, 50);
                setTimeout(function () {
                    calculateMenuHeight();
                }, 100);
            }
        });

        $navbarToggler.on('click', function () {
            // Toggle display of mobile menu and transform hamburger into close button
            toggleMenuClick(false);
        });

        function toggleMenuClick() {
            if ($mobilemenuWrapper.multilevelpushmenu('activemenu').length === 0) {
                expandMenu();
            }
            else {
                collapseMenu();
            }
        }

        function expandMenu() {
            $navbarTogglerHtml = $navbarToggler.html();
            $mobilemenuWrapper.multilevelpushmenu('expand');
            $navbarToggler.html("<span id=\"navbarTogglerText\">Close&nbsp;</span><span id=\"navbarTogglerIcon\">X</span>").addClass("closeLabel");
        }

        function collapseMenu() {
            $mobilemenuWrapper.multilevelpushmenu('collapse');
            $navbarToggler.removeClass("closeLabel").html($navbarTogglerHtml);
        }

        function calculateMenuHeight() {
            var menuOffset = $mobilemenuWrapper.offset().top;
            var screenHeight = window.innerHeight;
            var heightCalc = screenHeight - menuOffset;
            mainMenuLevel.height(heightCalc);
        }

        function ScrollMobileMenu($element) {
            //console.log($element);
            if (!$.inViewport($element.find('>ul>li:last-child'))) {
                $element.addClass('scrolled');
            }
            else {
                $element.removeClass('scrolled');
            }
        }

        $('.overlay').click(function (e) {
            collapseMenu();
        });

        /* Workaround what I believe to be a stacking context issue - header logo is clickable behind mobile menu search form */
        $(".nav-wrapper").click(function (e) {
            if ($mobilemenuWrapper.multilevelpushmenu('activemenu').length !== 0) return false;
        });
    });

    function isViewportInMobile(mobileWidthOverride) {
        var widthToCheckAgainst = mobileWidthOverride || 768;
        return window.innerWidth < widthToCheckAgainst;
    }
})(jQuery);