(function ($) {
    console.log("js bundle loaded");

    $(function () {
        $("[data-postbackDropdown]").on('change', function () {
            var url = window.location.href;
            if (window.location.href.indexOf("?") > -1) {
                url = url.slice(0, url.indexOf('?'));
            }

            url = url + "?" + $(this).attr('data-Querystring') + "=" + this.value.replace("&", "%26");
            window.location.href = url;
            return false;
        });
    });
    $(document).ready(function () {

        //debugger;
        var row = $('.newsletter-signup-row');
        if (row.length > 0) {
            $(row).attr('id', 'newsletter-signup-row');
            $('.utility-nav li:last a').on('click', function () {
                var link = this;
                $.smoothScroll({
                    scrollTarget: link.hash
                });
            });
        }

        // set links as clickable (find-links.js)
        Marathon.SetLinksAsClickable.OnReady();

        // set keyboard accessibility properties (accessibility.js)
        AccessibilityHelper.GiveKeyboardFocusAndClick.OnReady();
    });
})(jQuery);