(function($) {
    jQuery.extend(verge);
    $(document).ready(function() {
        var mobileNavId = "#mobile-primary-navigation";
        if ($(mobileNavId).length > 0) {
            $(mobileNavId).multilevelpushmenu({
                mode: 'cover',
                containersToPush: [$('header'), $('.footer-main'), $('main')],
                menuWidth: '100%',
                menuHeight: '100%'
            });
            $(window).resize(function() {
                $(mobileNavId).multilevelpushmenu('redraw');
            });
        }
    });
});