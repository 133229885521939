var AccessibilityHelper = (function ($) {

    this.GiveKeyboardFocusAndClick = {
        OnReady: function () {
            $(".keyboard-accessible").each(function () {
                $accessibleObject = $(this);

                // make able to be focused with KB
                $accessibleObject.attr("tabindex", 0);

                // disable KB focusing for screen reader only items
                $accessibleObject.find(".sr-only:not(.sr-only-focusable)").each(function () {
                    $(this).attr("tabindex", -1);
                });

                // trigger click event when object is focused and the enter key is pressed
                $accessibleObject.keypress(function (e) {
                    if (e.which == 13) {
                        // enter is pressed
                        e.preventDefault();
                        $(e.target).trigger('click');
                    }
                });
            });
        }
    };

    return this;
})(jQuery);