(function ($) {
    var ctaTime;
    $(function () {
        if ($("[data-equalHeights]").length) {

            $("[data-equalHeights]").each(function () {
                callEqualHeights($(this));
            });
        }
    });

    function isViewportInMobile(mobileWidthOverride) {
        var widthToCheckAgainst = mobileWidthOverride || 768;
        return window.innerWidth < widthToCheckAgainst;
    }

    function callEqualHeights($element) {
        var $elementToFind = $($element.attr('data-equalHeightCalc'));
        var autoHeightOnMobile = $element.attr('data-autoHeightOnMobile') || true;
        var viewportCutoff = $element.attr('data-autoHeightViewport') || 767;

        //Don't set the equal heights under a given viewport if specified
        if (autoHeightOnMobile && !isViewportInMobile(viewportCutoff)) {
            equalHeightHomepageCallouts($elementToFind);
        }

        window.onresize = function () {
            clearTimeout(ctaTime);

            ctaTime = setTimeout(function () {
                if (autoHeightOnMobile && isViewportInMobile(viewportCutoff)) {
                    $elementToFind.each(function () {
                        $(this).addClass('height-auto');
                    });
                }
                else {
                    equalHeightHomepageCallouts($elementToFind);
                    $elementToFind.each(function () {
                        if ($(this).hasClass('height-auto')) {
                            $(this).removeClass('height-auto');
                        }
                    });
                }
            }, 50);
        };
    }

    function equalHeightHomepageCallouts(group) {
        tallest = 0;

        group.each(function () {
            $(this).height("auto");
        });

        group.each(function () {
            thisHeight = $(this).height();
            if (thisHeight > tallest) {
                tallest = thisHeight;
            }
        });

        group.each(function () {
            $(this).height(tallest);
        });
    }
})(jQuery);
