(function ($) {

    if (!document.body.classList.contains('eventIndex')) {
        return;
    }

    var basepath = window.location.href.split("?")[0];
    var eventFilters = []
    var pageSize = 0;
    var currentPage = 0;

    function CreateEventFilter(name) {
        var obj = { name: name, values: [] }
        // grab the query string values
        var queryStringValue = getParameterByName(name);
        // parse query string values into array
        obj.values = ParseQueryStringValue(queryStringValue);
        // preselect the existing filters
        PreselectQueryStringFilter(name, obj.values);
        // find the field group associated with this filter
        var $fieldGroup = $(".event-" + name + "-fieldgroup");
        // set on click event to grab the values and filter events
        $fieldGroup.children("input").click(function () {
            obj.values = GetFieldGroupValues($fieldGroup);
            FilterEvents(false);
        });
        return obj;
    }

    $(function () {
        var $eventIndexContainer = $(".event-index-card-container");
        pageSize = $eventIndexContainer.data("page-size");

        // deselect radio button when selected again
        $(".select-multiple-filter input").click(function () {

            var $input = $(this);
            var dataSelected = $input.attr("data-selected");

            if (typeof dataSelected !== 'undefined' && dataSelected == "true") {
                $input.attr("data-selected", false);
                $input.attr("checked", false);
                return;
            }
            else {
                $input.attr("data-selected", true);
            }
        });

        // set slider animations
        $(".slider-select-filter label").click(function (e) {
            var $newLabel = $(this);
            var $parentFilterGroup = $newLabel.parent(".event-filters-group");
            var $labels = $parentFilterGroup.children("label");
            var $newInput = $parentFilterGroup.children("input[id='" + $newLabel.attr("for") + "']");

            // quit out if already checked
            if ($newInput.prop("checked") == true) {
                return;
            }

            var $oldInput = $parentFilterGroup.children("input:checked");
            var $oldLabel = $parentFilterGroup.children("label[for='" + $oldInput.attr("id") + "']");

            var oldLabelIndex = $labels.index($oldLabel);
            var newLabelIndex = $labels.index($newLabel);

            var labelIndexDiff = oldLabelIndex - newLabelIndex;
            var direction = (labelIndexDiff > 0) ? 1 : -1;

            var animationSpeed = 250;
            var animationStepSpeed = animationSpeed / Math.abs(labelIndexDiff);
            var i = oldLabelIndex;

            while (i < $labels.length && i >= 0) {
                var delayMagnitude = (-1 * direction) * (i - newLabelIndex) + Math.abs(newLabelIndex - oldLabelIndex) - 1;

                if (delayMagnitude < 0) {
                    delayMagnitude = 0;
                }

                var delay = delayMagnitude * animationStepSpeed;

                AnimateLabel($labels, i, direction, animationStepSpeed, delay);

                if (i == newLabelIndex) {
                    break;
                }

                i -= direction;
            }
        });

        // create event filters
        eventFilters = [
            CreateEventFilter("year"),
            CreateEventFilter("month"),
            CreateEventFilter("type")
        ];

        // give labels transition style after checkboxes are preselected to avoid having the initial filters transition.
        $(".event-filters-group label").css("transition", "background-color 0.2s ease-in-out");

        // get current page from query string
        currentPage = getParameterByName("page");
        if (currentPage.length <= 0) {
            // if query string param does not exist, current page is 1
            currentPage = 1;
        }
        else {
            // get the value as an int
            currentPage = parseInt(currentPage);
        }

        // slick month field group
        $(".event-month-fieldgroup").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: true,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 992,
                    settings: "unslick"
                }
            ]
        });

        // initial filter on page load
        FilterEvents(true);

        // show container
        $eventIndexContainer.css("visibility", "visible");

        // make add this a click event instead of default hover
        addthis.button('.addthis_button_compact', { ui_click: true }, {});
    });

    function ParseQueryStringValue(value) {
        if (value.length < 1) {
            return [];
        }

        return value.split(",").map(function (x) {
            return parseInt(x)
        });
    }

    function PreselectQueryStringFilter(name, values) {
        if (values == null || values.length < 1) {
            // if no value is provided in query string, try to select input with value = -1 (default value)
            var input = document.querySelector(".event-" + name + "-fieldgroup input[value='" + -1 + "']");

            preselectInput(input)
        }

        values.forEach(function (val) {
            // for each value, select the input
            var input = document.querySelector(".event-" + name + "-fieldgroup input[value='" + val + "']");

            preselectInput(input);
        });

        return true;
    }

    function GetFieldGroupValues($fieldGroup) {
        var $selectedInputs = $fieldGroup.children("input:checked");
        var values = [];
        $selectedInputs.each(function () {
            var numVal = parseInt($(this).val());
            if (numVal < 0) {
                return values;
            }
            values.push(numVal);
        });

        console.log("values", values);
        return values;
    }

    function FilterEvents(persistPage) {
        if (!persistPage) {
            currentPage = 1;
        }

        var $allEvents = $(".events .post-index-card");
        var $filteredEvents = $allEvents;

        eventFilters.forEach(function (filter) {

            if (filter.values.length <= 0) {
                return;
            }

            $filteredEvents = $filteredEvents.filter(function (index, element) {
                // go through each event
                var $element = $(element);
                // assume event should not be shown
                var shouldShow = false;
                // grab the data attribute for this filter
                var filterData = $element.data(filter.name);
                // loop through the values in the event's data attribute array
                filterData.forEach(function (val) {
                    // if a value is in the filter's value array, set shouldShow to true
                    if (filter.values.includes(val)) {
                        shouldShow = true;
                    } 
                });

                return shouldShow;
            });
        });

        $allEvents.attr("data-visible", false);
        $allEvents.attr("data-filtered", false);
        $filteredEvents.attr("data-filtered", true);
        $filteredEvents.slice(((currentPage - 1) * pageSize), (currentPage * pageSize)).attr("data-visible", true);

        if ($filteredEvents.length <= 0) {
            $(".events .no-results-found-text").show();
        }
        else {
            $(".events .no-results-found-text").hide();
        }

        var newurl = basepath + GetFiltersQueryString() + "page=" + currentPage;

        window.history.pushState({ path: newurl }, '', newurl);

        UpdatePagination();

        return true;
    }

    function UpdatePagination() {

        var filtersQueryString = GetFiltersQueryString();

        var $allEvents = $(".events .post-index-card");
        var $filteredEvents = $allEvents.filter("[data-filtered='true']");

        var numFilteredEvents = $filteredEvents.length;

        var $pagination = $("ul.pagination").empty(); // remove all page-items

        if (pageSize > numFilteredEvents) {
            // if single page, don't add pagination - return.
            return; 
        }

        // calculate max number of pages
        var numPages = Math.ceil(numFilteredEvents / pageSize);

        // add previous button
        $pagination.append(createPrevButton(currentPage, filtersQueryString));

        // calculate pagination start and end
        var paginationStart = Math.max(1, currentPage - 2);
        var paginationEnd = Math.min(numPages, currentPage + 2);

        // add page number buttons
        for (var i = paginationStart; i <= paginationEnd; i++) {
            $pagination.append(createPageItem(i, filtersQueryString));
        }

        // add next button
        $pagination.append(createNextButton(currentPage, numPages, filtersQueryString));

        // get all page items
        var $pageItems = $pagination.children(".page-item");

        // set current page as active
        if (numPages < 3) {
            $pageItems.eq(currentPage).addClass("active");
        }
        else {
            $pageItems.eq(paginationEnd - paginationStart - 1).addClass("active");
        }
    }

    function GetFiltersQueryString() {
        var queryString = "?";

        eventFilters.forEach(function (filter) {
            if (filter.values.length <= 0) {
                return;
            }

            queryString += filter.name + "=" + filter.values.join(',') + "&";
        });

        return queryString;
    }

    function AnimateLabel($labels, index, direction, speed, delay) {

        var $label = $labels.eq(index)
        var labelBgPosition = getLabelCurrentBgPosition($label);
        var newLabelBgPosition = labelBgPosition + (direction * 100);

        if (delay > 0) {
            // if delay, animate the background out of the previous label and then animate this label
            var $previousLabel = $labels.eq(index + direction);
            var prevLabelBgPosition = getLabelCurrentBgPosition($previousLabel);
            var newPrevLabelBgPosition = prevLabelBgPosition + (direction * 200);

            $previousLabel.addClass("animating");
            $previousLabel.animate({
                "background-position-x": newPrevLabelBgPosition + "%"
            }, speed, 'linear', function () {
                $previousLabel.css("background-position-x", getLimitedBgPosition(newPrevLabelBgPosition))
                $previousLabel.removeClass("animating");
            });

            setTimeout(function () {
                $label.animate({
                    "background-position-x": newLabelBgPosition + "%"
                }, speed, 'linear', function () {
                    $label.css("background-position-x", getLimitedBgPosition(newLabelBgPosition));
                });
            }, delay);
        }
        else {
            // if no delay, animate like normal
            $label.animate({
                "background-position-x": newLabelBgPosition + "%"
            }, speed, 'linear', function () {
                // skip this if already animating out
                if (!$label.hasClass("animating")) {
                    $label.css("background-position-x", getLimitedBgPosition(newLabelBgPosition));
                }
            });
        }
    }

    function preselectInput(input) {
        if (input == null) {
            return false;
        }

        // check the input and set the data-selected attribute
        input.checked = true;
        input.setAttribute("data-selected", true);

        if (input.classList.contains("slider-select-input")) {
            // if the input is a slider, set the backgroundPositionX style to 100%
            input.nextElementSibling.style.backgroundPositionX = "100%";
        }

        return true;
    }

    function getLabelCurrentBgPosition($label) {
        // to nearest 100
        return Math.round(parseInt($label.css("background-position-x")) / 100) * 100
    }

    function getLimitedBgPosition(currentBgPosition) {
        // limit so -200 < bgPosition < 200
        // if (currentBgPosition / 100) is even, return 0, else return 100
        var numHundred = Math.round(currentBgPosition / 100);
        return (numHundred % 2 == 0) ? "0%" : "100%";
    }

    function createPageItem(pageNumber, queryString) {
        return "<li class='page-item'><a class='page-link' href='" + queryString + "page=" + pageNumber + "'>" + pageNumber + "</a></li>";
    }

    function createPrevButton(currentPageNumber, queryString) {
        var prevPage = currentPageNumber - 1.0;
        var isDisabled = prevPage <= 0;

        return "<li class='page-item" + (isDisabled ? " disabled" : "") + "'><" + (isDisabled ? "span" : "a") + " class='page-link' aria-label='Previous'" + (isDisabled ? "" : " href='" + queryString + "page=" + prevPage + "'") + "><span aria-hidden='true'>«</span><span class='sr-only'>Previous</span></a></li>";
    }

    function createNextButton(currentPageNumber, numPages, queryString) {
        var nextPage = currentPageNumber + 1.0;
        var isDisabled = nextPage > numPages;

        return "<li class='page-item" + (isDisabled ? " disabled" : "") + "'><" + (isDisabled ? "span" : "a") + " class='page-link' aria-label='Next'" + (isDisabled ? "" : " href='" + queryString + "page=" + nextPage + "'") + "><span aria-hidden='true'>»</span><span class='sr-only'>Next</span></a></li>";
    }

    function getParameterByName(name) {
        name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
        var regexS = "[\\?&]" + name + "=([^&#]*)";
        var regex = new RegExp(regexS);
        var results = regex.exec(window.location.search);
        if (results == null)
            return "";
        else
            return decodeURIComponent(results[1].replace(/\+/g, " "));
    }

})(jQuery);

if (!Array.prototype.indexOf) {
    Array.prototype.indexOf = function (needle) {
        for (var i = 0; i < this.length; i++) {
            if (this[i] === needle) {
                return i;
            }
        }
        return -1;
    };
}
if (!Date.prototype.today) {
    //For todays date;
    Date.prototype.today = function () {
        return ((this.getDate() < 10) ? "0" : "") + this.getDate() + "/" + (((this.getMonth() + 1) < 10) ? "0" : "") + (this.getMonth() + 1) + "/" + this.getFullYear()
    };
}
if (!Date.prototype.timeNow) {
    //For the time now
    Date.prototype.timeNow = function () {
        return ((this.getHours() < 10) ? "0" : "") + this.getHours() + ":" + ((this.getMinutes() < 10) ? "0" : "") + this.getMinutes() + ":" + ((this.getSeconds() < 10) ? "0" : "") + this.getSeconds();
    };
}