//To use this functionality, simply add the class 'link-enabled' onto any container element that contains an anchor tag within it
//If the container features more than 1 child anchor tag, you can specify which anchor should take precedence by adding the 'preferred-link' class to that anchor element

var Marathon = (function ($) {

    this.SetLinksAsClickable = {
        OnReady: function () {
            $('.preferred-link').each(function () {
                var dataTag = $(this).closest('[data-link-enabled]');
                if (dataTag.exists()) {
                    dataTag.addClass('link-enabled');
                }
            });

            $('body').on('click', '.link-enabled', function (e) {

                //If users have clicked on an actual link inside of the div, allow them to access it
                var $target = $(e.target);
                if ($target.is('a')) { return; }

                //Otherwise prevent default actions & navigate them to the preferred link if one exists, or the nearest anchor tag
                e.preventDefault();
                var $anchor;

                if ($(this).find('.preferred-link').exists()) {
                    var $preferredLink = $(this).find('.preferred-link');
                    //preferred-link can get added as a class through the RTE and will be added onto a new span tag in some instances
                    if ($preferredLink.is('a')) $anchor = $preferredLink;
                    else if ($preferredLink.find('a').exists()) $anchor = $preferredLink.find('a');
                } else {
                    $anchor = $(this).find('a');
                }

                if ($anchor != null) {
                    /*
                    if ($anchor.is('[href^="http"], [href^="//"]') &&
                        $anchor.not('[href*="' + window.location.host + '"]') &&
                        $anchor.not('.exclude-external')) {

                        if (!window.confirm('You are being re-directed to ' + extractHostname($($anchor).attr('href')) + '.')) {
                            return false;
                        }
                    }*/

                    var url = $anchor.attr('href');
                    var userTarget = e.ctrlKey ? "_blank" : e.shiftKey ? "_blank" : $anchor.attr('target');
                    var smartTarget = url.indexOf("/") === 0 || url.indexOf("tel:") === 0 || url.indexOf("mailto:") === 0 ? "_self" : "_blank";
                    var target = userTarget || smartTarget;
                    window.open(url, target);
                } else {
                    console.warn('Anchor tag could not be found');
                }
            });
        }
    };

    return this;
})(jQuery);

//jQuery exists extension method
//To use, simply call .exists() on any jQuery object or selector to see if it exists on the page
$.fn.exists = function () {
    return this.length !== 0;
}